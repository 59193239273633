import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    meta: { title: 'kdo je viktorie' },
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/matematika',
    name: 'matematika',
    meta: { title: 'matematika' },
    component: () => import('../views/MatematikaView.vue')
  },
  {
    path: '/KISK',
    name: 'KISK',
    meta: { title: 'KISK' },
    component: () => import('../views/KiskView.vue')
  },
  {
    path: '/prvni-semestr',
    name: 'prvni-semestr',
    meta: { title: 'první semestr' },
    component: () => import('../views/Kisk-PrvniSemestr.vue')
  },
  {
    path: '/vkurzu',
    name: 'vkurzu',
    meta: { title: 'AI v kurzu' },
    component: () => import('../views/vkurzu.vue')
  },

  
  {
    path: '/zadani',
    name: 'zadani',
    meta: { title: 'Zadání úkolů' },
    component: () => import('../views/CalderonUkoly.vue')
  },
  {
    path: '/courses',
    name: 'couses',
    meta: { title: 'Předmět' },
    // component: () => import('../views/CalderonUkoly.vue')
    children: [
      {
        path: 'ISKM58',
        name: 'v-kurzu-projekt',
        meta: { title: 'ISKM58' },
        component: () => import('../views/courses/ISKM58.vue')
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue')
  }
  
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'viktoriino portfolio';
  next();
});

export default router